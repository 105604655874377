<ng-container *ngIf="credential; then isAuthenticatedTempl else isNotAuthenticatedTempl"></ng-container>

<ng-template #isAuthenticatedTempl>
  <div class="header header-authenticated navbar navbar-expand">
    <div class="centered-container collapse navbar-collapse" >
      <a class="navbar-brand" routerLink="/">
        <span class="navbar-title">xaneda</span>
        <div class="xaneda-logo">
          <img src="/assets/images/logos/logo-36x36.png" />
        </div>
      </a>
      <div xa-search></div>
      <ng-container *ngIf="deviceInfo.screenSizes['screen-small'] then  isHeaderSmallTempl else isHeaderRegularTempl"></ng-container>
    </div>
  </div>
  <!-- ---------{{ deviceInfo.screenSizes['screen-small']}} -- {{window.innerWidth }} -->
</ng-template>

<ng-template #isNotAuthenticatedTempl>
  <div class="header header-not-authenticated navbar navbar-expand">
    <div class="centered-container header-border">
      <a class="navbar-brand" style="display:flex;" routerLink="/">
        <span class="navbar-title">xaneda</span>
        <div class="xaneda-logo">
          <img src="/assets/images/logos/logo-36x36.png" alt="logo" />
        </div>
      </a>
      <div class="action-links">
        <a class="alert-link" routerLink="/register">{{'global.title.account.register' | translate}}</a>
        <a class="alert-link ml-10" routerLink="/login">{{'global.title.account.login' | translate}}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #isHeaderSmallTempl>
  <ul class="header-nav navbar-nav nav-justified">
    <li>
  <a class="nav-link flex-column settings-dropdown" [matMenuTriggerFor]="accountMenu" [routerLink]="">
    <img class="mat-list-avatar" [src]="credential.photoImage | image: 'avatar':'s1'" title="{{credential.firstName}} {{credential.lastName}}"
         alt="{{credential.firstName}} {{credential.lastName}}" />
    <div class="d-lg-inline dropdown-toggle "></div>
  </a>
  <mat-menu #accountMenu="matMenu" class="account-menu">
    <ul class="navbar-nav nav-dropdown">
      <li>
        <a routerLink="/profile/{{credential.slug}}" [state]="{profileId: credential.profileId}" class="nav-link" routerLinkActive="active">
          <img class="avatar" [src]="credential.photoImage | image: 'avatar':'s1'" title="{{credential.firstName}} {{credential.lastName}}"
               alt="{{credential.firstName}} {{credential.lastName}}" />
          <span>{{credential.firstName}} {{credential.lastName}}</span>
        </a>
      </li>
      <li>
        <a routerLink="preference" class="nav-link" routerLinkActive="active">
          <fa-icon icon="gear"></fa-icon>
          <span>{{'global.title.account.settings' | translate}}</span>
        </a>
      </li>
      <li *ngIf="credential.roleId == 2">
        <a routerLink="admin" class="nav-link" routerLinkActive="active">
          <fa-icon icon="wrench"></fa-icon>
          <span>{{'global.title.admin.main' | translate}}</span>
        </a>
      </li>
      <li>
        <a routerLink="logout" class="nav-link" routerLinkActive="active">
          <fa-icon icon="right-from-bracket"></fa-icon>
          <span>{{'global.title.account.logout' | translate}}</span>
        </a>
      </li>
    </ul>
    <div class="legal">
      <span class="dot-after"><a routerLink="/legal/imprint">{{'global.title.imprint' | translate}}</a></span>
      <span class="dot-after"><a routerLink="/legal/privacy">{{'global.title.privacy' | translate}}</a></span>
      <span><a routerLink="/legal/terms-of-use">{{'global.title.termsOfUse' | translate}}</a></span>
      <span><a routerLink="/help">{{'global.title.help' | translate}}</a></span>
      <span class="company">&copy; {{currentYear}} xanders GmbH ({{appVersion}})</span>
    </div>
  </mat-menu>
    </li>
  </ul>
</ng-template>

<ng-template #isHeaderRegularTempl>
  <ul class="header-nav navbar-nav nav-justified">
    <li class="nav-item">
      <a routerLink="/" [ngClass]="{active: activeNavItem=='start'}" class="nav-link flex-column" data-toggle="collapse">
        <fa-icon icon="home"></fa-icon>
        <span class="d-lg-inline">{{'global.title.start' | translate}}</span>
      </a>
    </li>
    <li class="nav-item">
      <a routerLink="/messages" [ngClass]="{active: activeNavItem=='messages'}" class="nav-link flex-column" *ngIf="unreadedMessages">
        <div class="badge-container" matBadge="{{unreadedMessages.length}}" matBadgeColor="accent" [matBadgeHidden]="unreadedMessages.length==0">
          <fa-icon icon="message"></fa-icon>
        </div>
        <span class="d-lg-inline">{{'navigation.messages.header' | translate}}</span>
      </a>
    </li>
    <li class="nav-item">
      <a routerLink="/network/board" [ngClass]="{active: activeNavItem=='network'}" class="nav-link flex-column" data-toggle="collapse">
        <div class="badge-container" matBadge="{{contactRequests.length}}" matBadgeColor="accent" [matBadgeHidden]="contactRequests.length==0">
          <fa-icon icon="people-arrows"></fa-icon>
        </div>
        <span class="d-lg-inline">{{'navigation.network.header' | translate}}</span>
      </a>
    </li>
    <!-- li class="nav-item">
      <a routerLink="/notifications" [ngClass]="{active: activeNavItem=='notifications'}" class="nav-link flex-column" data-toggle="collapse">
        <div class="badge-container" matBadge="{{numNotifications}}" matBadgeColor="accent" [matBadgeHidden]="numNotifications==0"><fa-icon icon="bell"></fa-icon></div>
        <span class="d-lg-inline">{{'navigation.notifications.header' | translate}}</span>
      </a>
    </li -->
    <li class="nav-item">
      <a [ngClass]="{active: activeNavItem=='more'}" class="nav-link flex-column" [matMenuTriggerFor]="moreMenu" [routerLink]="">
        <fa-icon icon="grip"></fa-icon>
        <span class="d-lg-inline dropdown-toggle">{{'navigation.more.header' | translate}}</span>
      </a>
      <mat-menu #moreMenu="matMenu" class="account-menu">
        <ul class="navbar-nav nav-dropdown">
          <li class="nav-item">
            <a routerLink="/events" [queryParams]="{filter: 'yours'}" class="nav-link flex-row">
              <fa-icon icon="calendar" class="mr-10"></fa-icon>
              <span class="d-lg-inline">{{'navigation.events.header' | translate}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/groups" [queryParams]="{filter: 'yours'}" class="nav-link flex-row">
              <fa-icon icon="users" class="mr-10"></fa-icon>
              <span class="d-lg-inline">{{'navigation.groups.header' | translate}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/organizations" [queryParams]="{filter: 'yours'}" class="nav-link flex-row">
              <fa-icon icon="industry" class="mr-10"></fa-icon>
              <span class="d-lg-inline">{{'navigation.organizations.header' | translate}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a routerLink="/jobs" [queryParams]="{filter: 'all'}" class="nav-link flex-row">
              <fa-icon icon="briefcase" class="mr-10"></fa-icon>
              <span class="d-lg-inline">{{'navigation.jobs.header' | translate}}</span>
            </a>
          </li>
        </ul>
      </mat-menu>
    </li>
    <li>
      <a class="nav-link  flex-column settings-dropdown" [matMenuTriggerFor]="accountMenu" [routerLink]="">
        <img class="mat-list-avatar" [src]="credential.photoImage | image: 'avatar':'s1'" title="{{credential.firstName}} {{credential.lastName}}"
             alt="{{credential.firstName}} {{credential.lastName}}" />
        <div class="d-lg-inline  dropdown-toggle "></div>
      </a>
      <mat-menu #accountMenu="matMenu">
        <ul class="navbar-nav nav-dropdown">
          <li>
            <a routerLink="/profile/{{credential.slug}}" [state]="{profileId: credential.profileId}" class="nav-link" routerLinkActive="active">
              <img class="avatar" [src]="credential.photoImage | image: 'avatar':'s1'" title="{{credential.firstName}} {{credential.lastName}}"
                   alt="{{credential.firstName}} {{credential.lastName}}" />
              <span>{{credential.firstName}} {{credential.lastName}}</span>
            </a>
          </li>
          <li>
            <a routerLink="preference" class="nav-link" routerLinkActive="active">
              <fa-icon icon="gear"></fa-icon>
              <span>{{'global.title.account.settings' | translate}}</span>
            </a>
          </li>
          <li *ngIf="credential.roleId == 2">
            <a routerLink="admin" class="nav-link" routerLinkActive="active">
              <fa-icon icon="wrench"></fa-icon>
              <span>{{'global.title.admin.main' | translate}}</span>
            </a>
          </li>
          <li>
            <a routerLink="logout" class="nav-link" routerLinkActive="active">
              <fa-icon icon="right-from-bracket"></fa-icon>
              <span>{{'global.title.account.logout' | translate}}</span>
            </a>
          </li>
        </ul>
      </mat-menu>
    </li>
  </ul>
</ng-template>
