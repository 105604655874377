import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable, throwError, of } from 'rxjs';
import { catchError, retryWhen, concatMap, delay } from 'rxjs/operators';

import { ErrorService } from '@core/services/error.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorhandlerInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly errorService: ErrorService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        const code = error?.error?.status || error?.status || '500';
        const message = error?.statusText || 'The cause of the error is unknown. Please contact your administrator';  //
        this.errorService.addError({
          code: code,
          message: message
        });
        if (code == '401') { // account unknown or wrong password
          return next.handle(req);
        }
        else {
          this.router.navigateByUrl('/error', {skipLocationChange: true});
        }
        return EMPTY;
      })
    );
  }
}
