import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import { map, switchMap, takeUntil, pairwise } from 'rxjs/operators';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { ImagePipe } from '@core/util/image.pipe';
import { SafeResourceUrlPipe } from '@core/util/safeResourceUrl.pipe';

@Component({
    selector: '[xa-image-viewer]',
    templateUrl: './image-viewer.component.html',
    styleUrls: ['./image-viewer.component.scss'],
    standalone: true,
    imports: [SafeResourceUrlPipe, ImagePipe, CommonModule, FontAwesomeModule]
})
export class ImageViewerComponent  implements OnInit {
  @ViewChild('slider', { static: true }) slider: ElementRef;
  @Input() images!: any;

  currentIndex = 0;
  transformStyle = 'translateX(0%)';

  touchStartX = 0;
  touchMoveX = 0;
  touchEndX = 0;
  touchMoveSubscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.initializeTouchEvents();
  }

  initializeTouchEvents() {
    const slider = this.slider.nativeElement;

    const touchStart$ = fromEvent<TouchEvent>(slider, 'touchstart');
    const touchMove$ = fromEvent<TouchEvent>(slider, 'touchmove');
    const touchEnd$ = fromEvent<TouchEvent>(slider, 'touchend');

    touchStart$.pipe(
      switchMap(startEvent => {
        const startX = startEvent.touches[0].clientX;

        return touchMove$.pipe(
          map(moveEvent => moveEvent.touches[0].clientX - startX),
          takeUntil(touchEnd$)
        );
      })
    ).subscribe(deltaX => {
      if (deltaX > 50) {
        this.prevSlide();
      } else if (deltaX < -50) {
        this.nextSlide();
      }
    });
  }

  prevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.images.length - 1;
    }
  }

  nextSlide() {
    if (this.currentIndex < this.images.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }
}
