<!-- div mat-dialog-title class="dialog-title">
  <button mat-icon-button mat-dialog-close>
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
</div -->
<mat-dialog-content>
  <button class="button-close" mat-icon-button mat-dialog-close>
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
  <div class="image-viewer" xa-image-viewer [images]="images" ></div>
</mat-dialog-content>
