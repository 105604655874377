import { Component, Input, HostListener } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Router, NavigationEnd, RouterLink } from '@angular/router';
import { filter } from 'rxjs/operators';

import { LANGUAGES } from '@app/config/language.constants';
import { appVersion } from '@app/config/version';
import { DeviceInfoService } from '@core/services/device-info.service';
import { AccountService } from '@modules/account/services/account.service';
import { FindLanguageFromKeyPipe } from '../../shared/language/find-language-from-key.pipe';
import { ActiveMenuDirective } from '../header/active-menu.directive';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: '[xa-footer]',
    styleUrls: ['./footer.component.scss'],
    templateUrl: './footer.component.html',
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        NgClass,
        FontAwesomeModule,
        MatBadgeModule,
        MatMenuModule,
        NgFor,
        ActiveMenuDirective,
        TranslateModule,
        FindLanguageFromKeyPipe,
    ],
})
export class FooterComponent {
  @Input() unreadedMessages: any;
  @Input() contactRequests: any;
  @Input() numNotifications: number;
	languages = LANGUAGES;
	isNavbarCollapsed = true;
	currentYear = (new Date()).getFullYear();
	appVersion: string = appVersion;
	credential: any;
	activeNavItem: any;

  constructor(
    private router: Router,
    private accountService: AccountService,
	  private translate: TranslateService,
    private cookieService: SsrCookieService,
    public deviceInfo: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.accountService.credential.subscribe(credential => {
      this.credential = this.accountService.credentialValue;
    });

    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        this.setActiveNavItem(ev.url);
      });
  }

  collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }

	changeLanguage(languageKey: string): void {
    this.cookieService.set('locale', languageKey, 999, '/');
    this.translate.use(languageKey);
  }

  @HostListener("window:resize", [])
  private onResize() {
    this.setActiveNavItem(this.router.url);
  }

  private setActiveNavItem(url: any) {
    if (!this.deviceInfo.screenSizes['screen-small'])
      return;

    if (url == '/') {
      this.activeNavItem = 'start';
    }
    if (url.startsWith('/messages')) {
      this.activeNavItem = 'messages';
    }
    if (url.startsWith('/network')) {
      this.activeNavItem = 'network';
    }
    if (url.startsWith('/notifications')) {
      this.activeNavItem = 'notifications';
    }
    if (url.startsWith('/groups') || this.router.url.startsWith('/organizations') || this.router.url.startsWith('/events')) {
      this.activeNavItem = 'more';
    }
  }
}
