import { ApplicationConfig, TransferState, importProvidersFrom, provideZoneChangeDetection, APP_INITIALIZER, APP_ID  } from '@angular/core';
import { CurrencyPipe, registerLocaleData  } from '@angular/common';
import { provideRouter } from '@angular/router';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { HttpBackend, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { NgxStripeModule } from 'ngx-stripe';

import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';

import { routes } from './app.routes';
import { Logger } from '@shared/logger/logger.service';
import { MetadataService } from '@core/services/metadata.service';
import { environment } from '../environments/environment';
import { httpInterceptorProviders } from '@core/interceptor/index';
import { CustomDateAdapter } from "@core/services/custom-date-adapter.service";
import { translateBrowserLoaderFactory, missingTranslationHandler } from '@core/loader/translate-browser.loader';

const googleClientId = environment.googleClientId;

registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEs, 'es');

export const appConfig: ApplicationConfig = {
  providers: [
    CurrencyPipe,
    httpInterceptorProviders,
    SsrCookieService,
    {
      provide: FaIconLibrary,
      useFactory: () => {
        const library = new FaIconLibrary();
        library.addIconPacks(fas, far);
        return library;
      }
    } ,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [{
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(googleClientId)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId')
          }
        ],
        onError: (err: any) => {
          console.error(err);
        }
      }
      // as SocialAuthServiceConfig
    },
    Logger,
    MetadataService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js'),
      FontAwesomeModule,
      NgxStripeModule.forChild(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (translateBrowserLoaderFactory),
          deps: [HttpClient, TransferState],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useFactory: missingTranslationHandler
        },
      }),
    ),
    { provide: MAT_DATE_LOCALE, useValue: 'default' },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    { provide: APP_ID,  useValue: 'xaneda' }
  ]
};
