<div class="panel-container overlay" *ngIf="!deviceInfo.screenSizes['screen-very-small'] || (deviceInfo.screenSizes['screen-very-small'] && isOpen)">
  <div class="header">
    <div *ngIf="deviceInfo.screenSizes['screen-very-small']">
      <button mat-icon-button (click)="close()">
        <fa-icon icon="arrow-left"></fa-icon>
      </button>
    </div>
    <div style="width: 100%" *ngIf="!sendTo && action==='new'">
      <div class="new-message">{{'message.new' | translate}}</div>
      <div xa-search-bar (selectedRecipients)="setParticipants($event)"></div>
    </div>
    <ng-template [ngIf]="participants && participants.length==1 && action!=='new'">
      <div class="recipient-container flex" *ngIf="participants[0].id">
        <a routerLink="/profile/{{participants[0].slug}}">
          <img class="small-avatar" [src]="participants[0].photoImage | image: 'avatar':'s1'" alt="{{firstAndLastName(participants[0])}}" />
        </a>
        <div class="message-panel flex-child dash">
          <a routerLink="/profile/{{participants[0].slug}}">
            <div class="name-line">{{firstAndLastName(participants[0])}}</div>
            <div *ngIf="participants[0].slogan">{{participants[0].slogan}}</div>
          </a>
        </div>
        <ng-container *ngTemplateOutlet="menu"></ng-container>
      </div>
      <div class="recipient-container flex" *ngIf="!participants[0].id">
        <img class="small-avatar" [src]="'' | image: 'avatar':'s1'" alt="{{'profile.deleted' | translate}}" />
        <div class="message-panel flex-child dash">
          <div class="name-line">{{'profile.deleted' | translate}}</div>
        </div>
        <ng-container *ngTemplateOutlet="menu"></ng-container>
      </div>
    </ng-template>
    <ng-template [ngIf]="participants && participants.length>=2 && action!=='new'">
      <div class="recipient-container">
        <div *ngFor="let participant of participants">
          <ng-template [ngIf]="participant.id">
            <a routerLink="/profile/{{participant.slug}}">
              <img class="small-avatar" [src]="participant.photoImage | image: 'avatar':'s1'" alt="{{firstAndLastName(participant)}}" />
            </a>
          </ng-template>
          <ng-template [ngIf]="!participant.id">
            <img class="avatar" [src]="'' | image: 'avatar':'s1'" alt="{{'profile.deleted' | translate}}" />
          </ng-template>
        </div>
        <div *ngTemplateOutlet="menu" [ngIf]="messages && messages.length > 0"></div>
      </div>
      <div class="name-line">
        <ng-container *ngFor="let participant of participants; first as isFirst; last as isLast">
          <ng-template [ngIf]="participant.id && isFirst">
            <a routerLink="/profile/{{participant.slug}}">
              {{firstAndLastName(participant)}}
            </a>
          </ng-template>
          <ng-template [ngIf]="participant.id && !isFirst && !isLast">
            , <a routerLink="/profile/{{participant.slug}}">
            {{firstAndLastName(participant)}}
          </a>
          </ng-template>
          <ng-template [ngIf]="participant.id && isLast">
            und <a routerLink="/profile/{{participant.slug}}">
            {{firstAndLastName(participant)}}
            </a>
          </ng-template>
          <ng-template [ngIf]="!participant.id && isFirst">{{'profile.deleted' | translate}}</ng-template>
          <ng-template [ngIf]="!participant.id && !isFirst && !isLast">, {{'profile.deleted' | translate}}</ng-template>
          <ng-template [ngIf]="!participant.id && isLast"> und {{'profile.deleted' | translate}}</ng-template>
        </ng-container>
      </div>
    </ng-template>
  </div>
  <div #messageListContainer class="message-list-container main">
    <div *ngFor="let message of messages;let i=index" class="message-container">
      <div class="sended-at" *ngIf="i==0 || (message.sendedAt | date: 'shortDate') !== (messages[i-1].sendedAt | date: 'shortDate') ">
        <span>{{ sendedAtDateAsString(message.sendedAt) }}</span>
      </div>
      <div class="message-detail">
        <div *ngIf="message.sender">
          <a routerLink="/profile/{{message.sender!.slug}}">
            <img class="avatar" [src]="message.sender!.photoImage! | image: 'avatar':'s1'" alt="{{firstAndLastName(message.sender!)}}" />
          </a>
        </div>
        <div *ngIf="!message.sender">
          <img class="avatar" [src]="'' | image: 'avatar':'s1'" alt="{{'profile.deleted' | translate}}" alt="{{'profile.deleted' | translate}}" />
        </div>
        <div class="message-panel flex-child dash">
          <div class="name-line">
            <a routerLink="/profile/{{message.sender!.slug}}" *ngIf="message.sender">
              {{firstAndLastName(message.sender!)}}
            </a>
            <span class="name-line" *ngIf="!message.sender">{{'profile.deleted' | translate}}</span>
            <span> - {{message.sendedAt | localizedDate: 'TIME_24_SIMPLE' }}</span>
          </div>
          <div class="message-body" [innerHTML]="message.textMsg" *ngIf="message.textMsg"></div>
          <div xa-media-player [src]="message.audioMsg" *ngIf="message.audioMsg"></div>
          <div class="viewed" *ngIf="message.sender.id == credential.profileId && message.viewed">
            <fa-icon icon="circle-check"></fa-icon>
          </div>
          <div *ngFor="let file of message.files" class="message-attachment">
            <img [src]="cdnUrl(file.url)" (click)="viewImage(file)"  *ngIf="file && file.fileType.startsWith('image')" />
            <a [href]="cdnUrl(file.url)" (click)="saveFileAs($event, file)" target="_blank" rel="noopener noreferrer"
               *ngIf="file && !file.fileType.startsWith('image')">
              <div class="attachment-container">
                <fa-icon [icon]="getIconForMimeType(file.fileType)"></fa-icon>
                <div class="name">
                  <div>
                    {{file.originalName}}
                  </div>
                  <div class="size">
                    {{ file.size | formatFileSize:false }}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="min-height: 50px" class="footer">
    <div *ngIf="active">
      <div *ngFor="let attachment of attachments" class="attachment-container pl">
        <div class="symbol" *ngIf="attachment.fileType.startsWith('image')" >
          <img height="30" width="30" [src]="attachment.url"/>
        </div>
        <div class="symbol" *ngIf="!attachment.fileType.startsWith('image')" >
          <fa-icon [icon]="getIconForMimeType(attachment.fileType)"></fa-icon>
        </div>
        <div class="name">
          <div>{{ attachment.originalName}}</div>
          <div class="size">{{ attachment.size | formatFileSize:false }}</div>
        </div>
        <button (click)="removeAttachment(attachment.id)" mat-icon-button class="action-button-rounded mr">
          <fa-icon icon="xmark" class="xmark"></fa-icon>
        </button>
      </div>
      <form [formGroup]="form" #ngForm="ngForm" class="footer-items" novalidate>
        <ng-template [ngIf]="!isMediaRecorderVisible">
          <button (click)="fileUpload.click()" mat-icon-button class="action-button-rounded mr">
            <fa-icon icon="paperclip"></fa-icon>
          </button>
          <!-- input type="file" hidden #fileUpload (change)="onFileSelected($event)" accept="image/jpeg,image/png,image/gif, video/mp4" / -->
          <input type="file" hidden #fileUpload (change)="onFileSelected($event)" accept="image/*,.pdf,.doc,.docx,.csv,.zip,.rar,.ppt,.pptx,.pps,.ppsx,.odt,.rtf,.xls,.xlsx,.txt" />
          <button (click)="toggleEmojiPicker()" mat-icon-button class="action-button-rounded mr">
            <fa-icon [icon]="['far', 'face-smile']"></fa-icon>
          </button>
          <emoji-mart class="emoji-mart" set="twitter" [showPreview]="false" [enableSearch]="false" (emojiSelect)="addEmoji($event)" *ngIf="showEmojiPicker"></emoji-mart>
          <div formControlName="textMsg" #textMsg contenteditable="true" (keyup)="hasCharsX($event)" class="contenteditable" attr.placeholder="{{'message.placeholder' | translate}}"></div>
          <!-- div class="is-submitted-wheel" *ngIf="isSubmitted"></div -->
          <button (click)="sendMessage()" mat-icon-button  [disabled]="isSubmitted || !participants || participants.length==0" *ngIf="hasChars">
            <fa-icon icon="paper-plane"></fa-icon>
          </button>
          <button (click)="activateAudioReorder()" mat-icon-button class="action-button-rounded ml" [disabled]="isSubmitted || !participants || participants.length==0" *ngIf="!hasChars">
            <fa-icon icon="microphone"></fa-icon>
          </button>
        </ng-template>
        <ng-template [ngIf]="isMediaRecorderVisible">
          <div xa-media-recorder (mediaBlobOut)="sendMessage($event)"></div>
        </ng-template>
      </form>
    </div>
    <div *ngIf="!isLoaded && !active">
      Sie können die Unterhaltung leider nicht mehr fortsetzen
    </div>
  </div>
</div>

<ng-template #menu>
  <div class="mat-menu">
    <mat-menu #appMenu="matMenu">
      <ng-template matMenuContent>
        <a mat-menu-item (click)="report()">{{'message.report' | translate}}</a>
        <a mat-menu-item (click)="archive()" *ngIf="!messages[0].archived">{{'message.archive' | translate}}</a>
        <a mat-menu-item (click)="restore()" *ngIf="messages[0].archived">{{'message.restore' | translate}}</a>
        <a mat-menu-item (click)="delete()">{{'message.delete' | translate}}</a>
      </ng-template>
    </mat-menu>
    <button class="action-button-rounded menu-btn" [matMenuTriggerFor]="appMenu">
      <fa-icon icon="ellipsis-h"></fa-icon>
    </button>
  </div>
</ng-template>
