<ng-container *ngIf="deviceInfo.screenSizes['screen-small'] then isFooterSmallTempl else isFooterRegularTempl"></ng-container>

<ng-template #isFooterSmallTempl>
  <div class="footer footer-small">
    <div class="centered-container">
      <!-- div class="company">&copy; {{currentYear}} xanders GmbH ({{appVersion}})</div -->

      <ul class="footer-nav navbar-nav" *ngIf="credential">
        <li class="nav-item">
          <a routerLink="/" [ngClass]="{active: activeNavItem=='start'}" class="nav-link flex-column" data-toggle="collapse">
            <fa-icon icon="home"></fa-icon>
            <span class="d-lg-inline">{{'global.title.start' | translate}}</span>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/messages" [ngClass]="{active: activeNavItem=='messages'}" style="position:relative" class="nav-link flex-column" *ngIf="unreadedMessages">
            <div class="badge-container" matBadge="{{unreadedMessages.length}}" matBadgeColor="accent" [matBadgeHidden]="unreadedMessages.length==0">
              <fa-icon icon="message"></fa-icon>
            </div>
            <span class="d-lg-inline ">{{'navigation.messages.header' | translate}}</span>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/network/board" [ngClass]="{active: activeNavItem=='network'}" class="nav-link flex-column" data-toggle="collapse">
            <div class="badge-container" matBadge="{{contactRequests.length}}" matBadgeColor="accent" [matBadgeHidden]="contactRequests.length==0">
              <fa-icon icon="people-arrows"></fa-icon>
            </div>
            <span class="d-lg-inline">{{'navigation.network.header' | translate}}</span>
          </a>
        </li>
        <!-- li class="nav-item">
          <a routerLink="/notifications" [ngClass]="{active: activeNavItem=='notifications'}" class="nav-link flex-column" data-toggle="collapse">
            <div class="badge-container" matBadge="{{numNotifications}}" matBadgeColor="accent" [matBadgeHidden]="numNotifications==0"><fa-icon icon="bell"></fa-icon></div>
            <span class="d-lg-inline">{{'navigation.notifications.header' | translate}}</span>
          </a>
        </li -->
        <li class="nav-item">
          <a [ngClass]="{active: activeNavItem=='more'}" class="nav-link flex-column " [matMenuTriggerFor]="moreMenu" [routerLink]="">
            <fa-icon icon="grip"></fa-icon>
            <span class="d-lg-inline  dropdown-toggle ">{{'navigation.more.header' | translate}}</span>
          </a>
          <mat-menu #moreMenu="matMenu">
            <ul class="navbar-nav nav-dropdown">
              <li class="nav-item">
                <a routerLink="/events" [queryParams]="{filter: 'yours'}" class="nav-link flex-row">
                  <fa-icon icon="calendar" class="mr-10"></fa-icon>
                  <span class="d-lg-inline">{{'navigation.events.header' | translate}}</span>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/groups" [queryParams]="{filter: 'yours'}" class="nav-link flex-row">
                  <fa-icon icon="users" class="mr-10"></fa-icon>
                  <span class="d-lg-inline">{{'navigation.groups.header' | translate}}</span>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/organizations" [queryParams]="{filter: 'yours'}" class="nav-link flex-row">
                  <fa-icon icon="industry" class="mr-10"></fa-icon>
                  <span class="d-lg-inline">{{'navigation.organizations.header' | translate}}</span>
                </a>
              </li>
              <li class="nav-item">
                <a routerLink="/jobs" [queryParams]="{filter: 'all'}" class="nav-link flex-row">
                  <fa-icon icon="briefcase" class="mr-10"></fa-icon>
                  <span class="d-lg-inline">{{'navigation.jobs.header' | translate}}</span>
                </a>
              </li>
            </ul>
          </mat-menu>
        </li>
      </ul>
      <ul class="footer-nav" *ngIf="!credential">
        <li class="nav-item">
          <a routerLink="/legal/imprint" class="nav-link">{{'global.title.imprint' | translate}}</a>
        </li>
        <li class="nav-item">
          <a routerLink="/legal/privacy" class="nav-link">{{'global.title.privacy' | translate}}</a>
        </li>
        <li class="nav-item">
          <a routerLink="/legal/terms-of-use" class="nav-link">{{'global.title.termsOfUse' | translate}}</a>
        </li>
        <li class="nav-item">
          <a routerLink="/help" class="nav-link">{{'global.title.help' | translate}}</a>
        </li>
        <li class="nav-item pointer" *ngIf="languages && languages.length > 1">
          <a class="nav-link dropdown-toggle" [matMenuTriggerFor]="languageMenu" [routerLink]="">
            <span>
              <span>{{'global.title.language' | translate}}</span>
            </span>
          </a>
          <mat-menu #languageMenu="matMenu">
            <ng-template matMenuContent>
              <a *ngFor="let language of languages" mat-menu-item [xaActiveMenu]="language" [routerLink]="" (click)="changeLanguage(language); collapseNavbar()">{{ language | findLanguageFromKey }}</a>
            </ng-template>
          </mat-menu>
        </li>
      </ul>
    </div>
  </div>
</ng-template>

<ng-template #isFooterRegularTempl>
  <div class="footer">
    <div class="centered-container">
      <div class="company">&copy; {{currentYear}} xanders GmbH ({{appVersion}})</div>
      <ul class="footer-nav">
        <li class="nav-item">
          <a routerLink="/legal/imprint" class="nav-link">{{'global.title.imprint' | translate}}</a>
        </li>
        <li class="nav-item">
          <a routerLink="/legal/privacy" class="nav-link">{{'global.title.privacy' | translate}}</a>
        </li>
        <li class="nav-item">
          <a routerLink="/legal/terms-of-use" class="nav-link">{{'global.title.termsOfUse' | translate}}</a>
        </li>
        <li class="nav-item">
          <a routerLink="/help" class="nav-link">{{'global.title.help' | translate}}</a>
        </li>
        <li class="nav-item pointer" *ngIf="languages && languages.length > 1">
          <a class="nav-link dropdown-toggle" [matMenuTriggerFor]="languageMenu" [routerLink]="">
            <span>
              <span>{{'global.title.language' | translate}}</span>
            </span>
          </a>
          <mat-menu #languageMenu="matMenu">
            <ng-template matMenuContent>
              <a *ngFor="let language of languages" mat-menu-item [xaActiveMenu]="language" [routerLink]="" (click)="changeLanguage(language); collapseNavbar()">{{ language | findLanguageFromKey }}</a>
            </ng-template>
          </mat-menu>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
