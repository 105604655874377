import { CommonModule } from '@angular/common';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, EMPTY } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'xa-errorhandler',
    templateUrl: './errorhandler.component.html',
    styleUrls: ['./errorhandler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, RouterModule, TranslateModule],
})
export class ErrorhandlerComponent implements OnInit {
  constructor(private readonly route: ActivatedRoute) {}

  data: Observable<{ code: number; message: string } | undefined> = EMPTY;

  ngOnInit(): void {
    this.data = this.route.data.pipe(
      map(
        (data) =>
          (data.errorData as { code: number; message: string }) || undefined
      )
    );
  }
}
