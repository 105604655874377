<div class="slider-container" #slider>
  <a (click)="prevSlide()" class="slider-btn previous" *ngIf="images.length > 1">
    <fa-icon class="chevron" icon="arrow-left"></fa-icon>
  </a>
  <a (click)="nextSlide()" class="slider-btn next" *ngIf="images.length > 1">
    <fa-icon class="chevron" icon="chevron-right"></fa-icon>
  </a>
  <div class="slider" [style.transform]="transformStyle">
    <div class="slide" *ngFor="let image of images">
      <img [src]="images[currentIndex].url | image" alt="Image Slider">
    </div>
  </div>
</div>
