import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { take } from 'rxjs';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

declare const google: any;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[google-signin-button]',
    standalone: true,
})
export class GoogleSigninButtonDirective implements OnInit {
  @Input() text: string;

  constructor(
    private el: ElementRef,
    private socialAuthService: SocialAuthService,
    private cookieService: SsrCookieService,
   ) {}

  ngOnInit() {
    this.socialAuthService.initState.pipe(take(1)).subscribe(() => {
      google.accounts.id.renderButton(this.el.nativeElement, {
        type: 'standard',
        size: 'medium',
        text: this.text,
        theme: 'outline',
        locale: this.cookieService.get('locale')
      });
    });
  }
}
