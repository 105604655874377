import { Component, ViewChild, ElementRef,EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { MaterialModule } from '@shared/material.module';
import { Credential } from '@modules/account/models/credential.model';
import { AccountService } from '@modules/account/services/account.service';
import { Profile } from '@modules/profile/models/profile.model';
import { ProfileService } from '@modules/profile/services/profile.service';
import { ImagePipe } from '@core/util/image.pipe';

@Component({
    selector: '[xa-search-bar]',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
    standalone: true,
    imports: [CommonModule, MaterialModule, FontAwesomeModule, TranslateModule, ImagePipe]
})
export class SearchBarComponent {
  @ViewChild('input') input: ElementRef;
  @Output() selectedRecipients = new EventEmitter();
  credential: Credential;
  selectedProfiles: Profile[] = [];
  filteredProfiles: Profile[] = [];

  constructor(
    private accountService: AccountService,
    private profileService: ProfileService
  ) {
    this.credential = this.accountService.credentialValue;
  }

  getProfiles(filter: any): any {
    const chars = filter ? filter.target.value : null;
    if (chars == null || chars.length < 3) { return };
    const profileIds = this.selectedProfiles.map(function(_profile: any) {
      return _profile.id;
    });
    profileIds.push(this.credential.profileId);
    let params = new HttpParams();
    params = params.append('search', chars);
    params = params.append('not', profileIds.join(','));
    this.profileService.getProfiles(params).subscribe(profiles => {
      this.filteredProfiles = profiles;
    });
  }

  selectProfile(profile: any): void {
    if (!profile) {
      this.selectedProfiles = []
    } else {
      this.selectedProfiles.push(profile);
      this.selectedRecipients.emit(this.selectedProfiles)
    }
    this.filteredProfiles = [];
    this.focusOnPlaceInput();
  }

  removeProfile(profile: any): void {
    const index = this.selectedProfiles.indexOf(profile);
    if (index >= 0) {
      this.selectedProfiles.splice(index, 1);
    }
    this.focusOnPlaceInput();

    this.selectedRecipients.emit(this.selectedProfiles)
  }

  focusOnPlaceInput(): void {
    this.input.nativeElement.focus();
    this.input.nativeElement.value = '';
  }
}
