import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MaterialModule } from '@shared/material.module';
import { AccountService } from '@modules/account/services/account.service';
import { MetadataService } from '@core/services/metadata.service';

@Component({
  selector: 'xa-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  standalone: true,
  imports: [TranslateModule, RouterModule, CommonModule]
})
export class LogoutComponent implements OnInit {

  constructor(
    private accountService: AccountService,
    private translate: TranslateService,
    private metadataService: MetadataService
  ) { }

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: this.translate.instant('logout.title'),
      description: this.translate.instant('logout.title'),
      type: "website"
    });
    this.accountService.logout();
  }
}
