import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { DateTime } from 'luxon';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { TranslateModule } from '@ngx-translate/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { FileSaverService } from 'ngx-filesaver';
import { filter, mergeMap, map } from 'rxjs/operators';
import { from } from 'rxjs';

import { MaterialModule } from '@shared/material.module';
import { Credential } from '@modules/account/models/credential.model';
import { AccountService } from '@modules/account/services/account.service';
import { Message } from '@modules/message/models/message.model';
import { MessageService } from '@modules/message/services/message.service';
import { Profile } from '@modules/profile/models/profile.model';
import { ProfileService } from '@modules/profile/services/profile.service';
import { environment } from '@environments/environment';
import { MessageSwitchComponent } from './message-switch.component';
import { ReportComponent } from '@modules/report/components/report.component';
import { FileUploadService } from '@core/services/file-upload.service';
import { MediaPlayerComponent } from '@shared/media-player/media-player.component';
import { ContenteditableValueAccessor } from '@shared/contenteditable/contenteditable-value-accessor';
import { MediaRecorderComponent } from '@shared/media-recorder/media-recorder.component';
import { FormatFileSizePipe } from '@core/util/format-file-size.pipe';
import { ImagePipe } from '@core/util/image.pipe';
import { LocalizedDatePipe } from '@shared/language/localized-date.pipe';

@Component({
    selector: '[xa-message-thread-modal]',
    templateUrl: './thread-modal.component.html',
    styleUrls: ['./thread-modal.component.scss'],
    standalone: true,
    imports: [MaterialModule, CommonModule, RouterModule, FontAwesomeModule, MediaPlayerComponent, FormsModule,
      ReactiveFormsModule, PickerModule, ContenteditableValueAccessor, MediaRecorderComponent, TranslateModule,
      FormatFileSizePipe, ImagePipe, LocalizedDatePipe]
})
export class ThreadModalComponent implements OnInit {
  @HostBinding('className') componentClass: string;
  @ViewChild('messageListContainer') messageListContainer: ElementRef;
  @ViewChild("textMsg") textMsgRef: ElementRef;
  @Input() threadId: any;
  @Input() sendToId: any;
  isMediaRecorderVisible: boolean = false;
  isSubmitted: boolean = false;
  action: string;
  form: any;
  error: boolean = false;
  messages: any = null;
  credential: Credential;
  profile: any;
  participant: any;
  isOpen: any;
  showEmojiPicker = false;
  isLoaded = false;
  scrollToBottom = false;
  isContenteditable = false;
  hasChars = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  attachments: any = [];
  files: any = [];

  public messageSwitchRef: MessageSwitchComponent;

  blobToBase64 = (blob: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private profileService: ProfileService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private uploadService: FileUploadService,
    private http: HttpClient,
    private fileSaverService: FileSaverService
  ) {
    this.componentClass = 'xa-message-thread-modal';
    this.form = this.formBuilder.group({
      textMsg: [''],
      audioMsg: [''],
      participantId: [],
      senderId: [],
      sendedAt: [],
      threadId: [],
    });

    this.credential = this.accountService.credentialValue;
    this.form.controls.textMsg.setValue("");
  }

  ngOnInit(): void {
    if (!this.threadId) {
      this.initMessageThread(this.sendToId);
    }
    else {
      this.getAllMessagesOfThread(this.threadId);
    }
  }

  ngAfterViewChecked(): void {
    if (this.scrollToBottom) {
      this.messageListContainer.nativeElement.scrollTop = this.messageListContainer.nativeElement.scrollHeight;
      this.scrollToBottom = false;
    }
    /* if (this.textMsgRef) {
      this.textMsgRef.nativeElement.focus();
    } */
  }

  initMessageThread(threadId: string): void {
  }

  getAllMessagesOfThread(threadId: string): void {
    this.isLoaded = false;
    this.messageService.getAllMessagesOfThread(threadId, new HttpParams()
      .set('my', this.credential.profileId)).subscribe(resp => {
      let messages = resp.data.messages;

      this.participant = resp.data.participants.filter((participant:any) => (
              !participant.id || (participant.id && participant.id !== this.credential.profileId)))[0];

      for (const message of messages) {
        if (message.textMsg) {
          message.textMsg = message.textMsg!.replace(/\n|\r\n|\r/g, '<br/>');
        }
        if (message.audioMsg) {
          message.audioMsg = environment.cdnUrl.concat(message.audioMsg);
        }
      }
      this.messages = messages;
      this.form.controls.textMsg.reset();
      this.form.controls.audioMsg.reset();
      this.checkIsContenteditable();
      this.scrollToBottom = true;
      this.messageService.setCurrentThreadId(threadId);
    });
  }

  async sendMessage(audioMsg ? : any): Promise < void > {
    this.files = [];
    if (this.form.valid) {
      this.isSubmitted = true;
      this.isMediaRecorderVisible = false;
      if (audioMsg) {
        if (!audioMsg.audioBlob) return;
        const _blobToBase64 = await this.blobToBase64(audioMsg.audioBlob)
        this.form.patchValue({
          audioMsg: _blobToBase64
        });
      }
      let obj = this.formToObject();
      let message: Message = {};
      if (this.attachments && this.attachments.length !== 0) {
        this.messageService.saveMessage(obj).pipe(
          map(resp => {
            message.id = resp.data.id;
            message.threadId = resp.data.threadId;
            return message;
          }),
          mergeMap(_message =>
            from(this.attachments).pipe(
              mergeMap((attachment:any) =>
                this.uploadService.upload(this.getFormData(_message, attachment))
              )
            )
          )
        ).subscribe(resp => {
          this.files.push(resp);
          if (this.attachments.length == this.files.length) {
            this.attachments = [];
            this.continue(message);
          }
        })
      } else {
        this.messageService.saveMessage(obj).subscribe((resp) => {
          message.threadId = resp.data.threadId;
          this.continue(message);
        });
      }
    }
  }

  private getFormData(message: any, file: any): FormData {
    let formData: any = {};
    formData.targetId = message.id;
    formData.targetType = 'message';
    formData.originalName = file.name;
    formData.fileType = file.type;
    formData.fileSize = file.size;
    formData.file = file.url;
    return formData;
  }

  private continue (message: Message): void {
    if (this.form.controls.textMsg.value) {
      message.textMsg = this.form.controls.textMsg.value.replace(/\n|\r\n|\r/g, '<br/>');
    }
    message.audioMsg = this.form.controls.audioMsg.value;
    const sender: any = {
      id: this.credential.profileId,
      firstName: this.credential.firstName,
      lastName: this.credential.lastName,
      slug: this.credential.slug,
      slogan: this.credential.slogan,
      photoImage: this.credential.photoImage
    }
    message.sender = sender;
    message.sendedAt = DateTime.local();
    if (this.messages == undefined) {
      this.messages = [];
    }
    this.messages.push(message);
    // this.participants = message.recipients;
    this.form.reset();
    this.action = "";
    this.scrollToBottom = true;
    this.isSubmitted = false;
  }

  checkIsContenteditable() {
    this.isContenteditable = true;
  }

  formToObject(): any {
    Object.keys(this.form.controls).map((key) => {
      const control = this.form.get(key);
      if (control.value !== undefined && control.value != null && control.value.length == 0) {
        control.setValue(null);
      }
    });
    const obj:any = {};
    obj.sender = this.credential.profileId;
    obj.recipients = [{recipientId: this.participant.id}]
    obj.threadId = this.threadId;
    obj.textMsg = this.form.value.textMsg;
    obj.audioMsg = this.form.value.audioMsg;
    obj.sendedAt = new Date();
    return obj;
  }

  firstAndLastName(profile: Profile): string {
    return profile.firstName!.concat(" ").concat(profile.lastName!);
  }

  toggleEmojiPicker(): void {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  /* eslint-disable @typescript-eslint/restrict-template-expressions */
  addEmoji(event: any): void {
    const textMsg = this.textMsgRef.nativeElement.innerHTML;
    const textMsgPlusEmoji = `${textMsg}${event.emoji.native}`;
    this.form.get("textMsg").setValue(textMsgPlusEmoji);
    this.showEmojiPicker = false;
  }

  onFocus(): void {
    this.showEmojiPicker = false;
  }

  onBlur(): void {
    this.showEmojiPicker = false;
  }

  hasCharsX(event: any): void {
    this.hasChars = this.form.value.textMsg.length > 0 ? true : false;
  }

  activateAudioReorder() {
    this.isMediaRecorderVisible = true;
  }

  remove(id: number) {
    this.messageSwitchRef.remove(id)
  }

  report(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = '800px';
    dialogConfig.data = {
      objectId: this.threadId,
      objectType: 'Thread',
    };

    const dialogRef = this.dialog.open(ReportComponent,
      dialogConfig);

  }

  archive(): void {
    const statusObj = {
      threadId: this.threadId,
      participantId: this.credential.profileId
    }
    this.messageService.setArchiveStatus(statusObj).subscribe(
      (resp) => {});
  }

  delete(): void {
    const threadObj = {
      threadId: this.threadId,
      participantId: this.credential.profileId
    }
    this.messageService.deleteThread(threadObj).subscribe(
      (resp) => {});
  }

  onFileSelected(event: any): void {
    let selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles[0]) {
      let reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        this.attachments.push({
          url: e.target.result,
          type: "video"
        });
      }
      reader.readAsDataURL(selectedFiles[0]);
    };
  }


  cdnUrl(url: string): string {
    return environment.cdnUrl.concat(url);
  }

  // accepted: image/*,.pdf,.doc,.docx,.csv,.zip,.rar,.ppt,.pptx,.pps,.ppsx,.odt,.rtf,.xls,.xlsx,.txt"
  getIconForMimeType(mimeType: string):IconName {
    let icon = null;
    switch (mimeType) {
      // pdf
      case "application/pdf":
        icon = "file-pdf" as IconName;
        break;

      // csv
      case "text/csv":
        icon = "file-csv" as IconName;
        break;

      // csv, rtf
      case "text/plain":
      case "application/rtf":
        icon = "file-lines" as IconName;
        break;

      // .doc, .dot
      case "application/msword":
      // .docx
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      // .odt
      case "application/vnd.oasis.opendocument.text":
        icon = "file-word" as IconName;
        break;

      // .xls
      case "application/vnd.ms-excel":
      // .xlsx
      case "application / vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        icon = "file-excel" as IconName;
        break;

      // .ppt
      case "application/vnd.ms-powerpoint":
      // .pptx
      case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        icon = "file-powerpoint" as IconName;
        break;

      default:
        icon = "file" as IconName;
    }
    return icon;
  }

  saveFileAs(event: Event, file: any): void {
    event.preventDefault();
    this.http.get(`${environment.cdnUrl}${file.url}`, {
        responseType: "blob"
      }).toPromise()
      .then((blob: any) => {
        this.fileSaverService.save(blob, file.originalName);
      })
      .catch(err => console.error("download error = ", err))
  }

  removeAttachment(id: number) {
    this.attachments = this.attachments.filter((attachment:any) => attachment.id !== id);
  }
}
