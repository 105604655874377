import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '@shared/material.module';
import { Credential } from '@modules/account/models/credential.model';
import { AccountService } from '@modules/account/services/account.service';
import { Visit } from '@modules/visit/models/visit.model';
import { VisitService } from '@modules/visit/services/visit.service';
import { RouterModule } from '@angular/router';
import { ImagePipe } from '@core/util/image.pipe';
import { LocalizedDatePipe } from '@shared/language/localized-date.pipe';

@Component({
    selector: '[xa-list-visits]',
    templateUrl: './list-visits.component.html',
    styleUrls: ['./list-visits.component.scss'],
    standalone: true,
    imports: [CommonModule, MaterialModule, RouterModule, NgxPaginationModule, TranslateModule, ImagePipe,
      LocalizedDatePipe]
})
export class ListVisitsComponent implements OnInit {
  @Input() limit: any = false;
  credential: Credential;
  visits: any;
  page = 1;
  totalItems = 0;
  pageSize = 10;

  constructor(
    private accountService: AccountService,
    private visitService: VisitService,
  ) {}

  ngOnInit(): void {
    this.credential = this.accountService.credentialValue;
    if (this.credential && this.limit) {
      this.getVisits(this.limit);
    } else {
      this.getVisits(undefined);
    }
  }

  getVisits(limit: any): void {
    const params = this.getHttpParams(limit, this.page, this.pageSize);
    this.visitService.getVisits(params).subscribe(resp => {
      if (this.limit) {
        this.visits = resp.data.visits.rows;
      }
      else {
        this.visits = resp.data.visits.rows;
        this.totalItems = resp.data.visits.totalItems;
      }
    });
  }

  handlePageChange(event: number): void {
    this.page = event;
    this.getVisits(undefined);
  }

  private getHttpParams(limit: number, page: number, pageSize: number): any {
    let params = new HttpParams();
    params = params.append('my', this.credential.profileId);
    if (limit !== undefined) {
      params = params.append('limit', limit);
    }
    else {
      if (page) {
        params = params.append('page', (page - 1).toString());
      }
      if (pageSize) {
        params = params.append('size', pageSize.toString());
      }
    }
    return params;
  }
}
