import { Component, OnInit, Input, Inject, HostListener } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router, NavigationEnd, RouterLink, RouterLinkActive } from '@angular/router';
import { filter } from 'rxjs/operators';

import { Logger } from '@shared/logger/logger.service';
import { appVersion } from '@app/config/version';
import { DeviceInfoService } from '@core/services/device-info.service';
import { AccountService } from '@modules/account/services/account.service';
import { Credential } from '@modules/account/models/credential.model';
import { ImagePipe } from '../../core/util/image.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMenuModule } from '@angular/material/menu';
import { SearchComponent } from '@modules/search/components/search.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: '[xa-header]',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        SearchComponent,
        MatMenuModule,
        RouterLinkActive,
        FontAwesomeModule,
        NgClass,
        MatBadgeModule,
        TranslateModule,
        ImagePipe,
    ],
})
export class HeaderComponent implements OnInit {
  @Input() unreadedMessages: any = [];
  @Input() contactRequests: any = [];
  @Input() numNotifications: number;
  currentYear = (new Date()).getFullYear();
  appVersion: string = appVersion;
  credential: Credential;
  activeNavItem: any;
  window: any;

  constructor(
    private router: Router,
    private accountService: AccountService,
    public deviceInfo: DeviceInfoService,
    private logger: Logger
  ) {}

  ngOnInit(): void {
    this.window = window;
    this.accountService.credential.subscribe(credential => {
      this.credential = this.accountService.credentialValue;
    });

    this.router.events.pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((ev: NavigationEnd) => {
        this.setActiveNavItem(ev.url);
      });
  }

  ngAfterViewInit(): void {

  }

  @HostListener("window:resize", [])
  private onResize() {
    this.setActiveNavItem(this.router.url);
  }

  private setActiveNavItem(url: any) {
    if (!this.deviceInfo.screenSizes['screen-large'])
      return;

    if (url == '/') {
      this.activeNavItem = 'start';
    }
    if (url.startsWith('/messages')) {
      this.activeNavItem = 'messages';
    }
    if (url.startsWith('/network')) {
      this.activeNavItem = 'network';
    }
    if (url.startsWith('/notifications')) {
      this.activeNavItem = 'notifications';
    }
    if (url.startsWith('/groups') || this.router.url.startsWith('/organizations') || this.router.url.startsWith('/events')) {
      this.activeNavItem = 'more';
    }
  }
}
