<div class="flex-layout">
  <div class="panel-container panel-small">
    <h1 class="h1-small">{{'login.title' | translate}}</h1>
    <div class="alert alert-danger" *ngIf="authenticationError && reason">{{ ('error.reason.' + reason) | translate}}</div>
    <form [formGroup]="form" (ngSubmit)="doLogin()">
      <div class="mb-20">
        <mat-form-field>
          <input matInput formControlName="login" placeholder="{{'login.form.login.placeholder' | translate}}" />
        </mat-form-field>
      </div>
      <div class="mb-20">
        <mat-form-field class="password-field">
          <input matInput [attr.type]="isPasswordVisible ? 'text' : 'password'" formControlName="password" placeholder="{{'login.form.password.placeholder' | translate}}" />
          <fa-icon [icon]="(!isPasswordVisible ? 'eye-slash' : 'eye')" (click)="onclicktoggle()"></fa-icon>
        </mat-form-field>
      </div>
      <div class="is-submitted-wheel" *ngIf="isSubmitted"></div>
      <button type="submit" mat-flat-button color="primary" [disabled]="!form.valid || isSubmitted">{{'login.form.button' | translate}}</button>
    </form>
    <div class="box">
      <a routerLink="/recover/request">{{'login.password.forgot' | translate}}</a>
    </div>
    <div class="box">
      <div>{{'global.messages.info.register.noaccount' | translate}}</div>
      <a routerLink="/register">{{'global.messages.info.register.link' | translate}}</a>
    </div>
    <div class="or-label-container">
      <span class="line"></span>
      <span class="label">{{'register.form.or' | translate}}</span>
    </div>
    <div class="google-signin-button">
      <div google-signin-button [text]="'continue_with'"></div>
    </div>
  </div>
</div>
