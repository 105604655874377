import { Routes } from '@angular/router';

import { LoginComponent } from '@modules/login/components/login.component';
import { LogoutComponent } from '@modules/logout/components/logout.component';
import { NetworkComponent } from '@modules/network/components/network.component';
import { ListVisitsComponent } from '@modules/visit/components/list-visits.component';
import { ErrorhandlerComponent, ErrorResolver, WrongRouteComponent } from '@modules/errorhandler';

export const routes: Routes = [{
    path: '',
    loadChildren: () => import('@modules/start/start.route').then(r => r.StartRoutes)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'legal',
    loadChildren: () => import('@modules/legal/legal.route').then(r => r.LegalRoutes)
  },
  {
    path: 'help',
    loadChildren: () => import('@modules/help/help.route').then(r => r.HelpRoutes)
  },
  {
    path: 'network',
    loadChildren: () => import('@modules/network/network.route').then(r => r.NetworkRoutes)
  },
  {
    path: 'profile',
    loadChildren: () => import('@modules/profile/profile.route').then(r => r.ProfileRoutes)
  },
  {
    path: 'messages',
    loadChildren: () => import('@modules/message/message.route').then(r => r.MessageRoutes)
  },
  {
    path: 'notifications',
    loadChildren: () => import('@modules/notification/notification.route').then(r => r.NotificationRoutes)
  },
  {
    path: 'visits',
    component: ListVisitsComponent
  },
  {
    path: 'posts',
    loadChildren: () => import('@modules/post/post.route').then(r => r.PostRoutes)
  },
  {
    path: 'events',
    loadChildren: () => import('@modules/event/event.route').then(r => r.EventRoutes)
  },
  {
    path: 'groups',
    loadChildren: () => import('@modules/group/group.route').then(r => r.GroupRoutes)
  },
  {
    path: 'organizations',
    loadChildren: () => import('@modules/organization/organization.route').then(r => r.OrganizationRoutes)
  },
  {
    path: 'jobs',
    loadChildren: () => import('@modules/job/job.route').then(r => r.JobRoutes)
  },
  {
    path: 'products',
    loadChildren: () => import('@modules/product/product.route').then(r => r.ProductRoutes)
  },
  {
    path: 'admin',
    loadChildren: () => import('@modules/admin/admin.route').then(r => r.AdminRoutes)
  },
  {
    path: 'preference',
    loadChildren: () => import('@modules/preference/preference.route').then(r => r.PreferenceRoutes)
  },
  {
    path: 'error',
    component: ErrorhandlerComponent,
    resolve: {
      errorData: ErrorResolver
    },
  },
  {
    path: '',
    loadChildren: () => import('@modules/account/account.route').then(r => r.AccountRoutes)
  },
  {
    path: '**',
    component: WrongRouteComponent
  }
];
