import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { MaterialModule } from '@shared/material.module';
import { ImageViewerComponent } from '@shared/image-viewer/image-viewer.component';

@Component({
    selector: '[xa-image-viewer-dialog]',
    templateUrl: './image-viewer-dialog.component.html',
    styleUrls: ['./image-viewer-dialog.component.scss'],
    standalone: true,
    imports: [ImageViewerComponent, MaterialModule, FontAwesomeModule ]  // CdkScrollable, ImagePipe
})
export class ImageViewerDialogComponent {
  images: any;

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ImageViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: {images: any}
  ) {
   this.images = data.images;
  }
}
