import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '@environments/environment';

@Pipe({
  name: 'image',
  standalone: true
})
export class ImagePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  defaultImage = "";
  transform(image: string, type ? : string, size ? : string): any {
    if (image && image.includes('base64')) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(image);
    } else {
      if (!image) {
        switch (type) {
          case 'avatar': {
            this.defaultImage = '/assets/images/people.svg';
            break;
          }
          case 'group': {
            this.defaultImage = '/assets/images/group.svg';
            break;
          }
          case 'event': {
            this.defaultImage = '/assets/images/event.svg';
            break;
          }
          case 'organization': {
            this.defaultImage = '/assets/images/organization.svg';
            break;
          }
          case 'background': {
            this.defaultImage = '/assets/images/background.jpg';
            break;
          }
          default: {
            break;
          }
        }
      } else if (image && size) {
        image = image.concat(size);
      }
      return image ? environment.cdnUrl.concat(image) : environment.appUrl.concat(this.defaultImage);
    }
  }
}
