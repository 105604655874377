import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthExpiredInterceptor } from "@core/interceptor/auth-expired.interceptor";
import { CacheInterceptor } from "@core/interceptor/cache.interceptor";
import { ErrorhandlerInterceptor } from "@core/interceptor/errorhandler.interceptor";
import { IdentInterceptor } from "@core/interceptor/ident.interceptor";
import { JwtInterceptor } from "@core/interceptor/jwt.interceptor";
import { LoadingInterceptor } from "@core/interceptor/loading.interceptor";

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthExpiredInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorhandlerInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: IdentInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingInterceptor,
    multi: true,
  }
];
